.library {
    margin-top:25vh
}

.library h1 {
    text-align: center;
    font-family: "Madimi One";
    font-size: 4vw; /* Adjust font size to be 5% of the viewport width */
    margin-top: 10vh; /* Set margin-top to create space between header and SVG */
    display: flex; /* Use flex display */
    justify-content: center; /* Center text horizontally */
}
.library-grid-container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: center;
}

.library-monster-container {
    border-radius: 25px;
    margin: 1rem; /* Adjust margin for spacing */
    padding: 2rem;
    position: relative;
    flex: 1 0 20%; /* Flex item takes up 20% of the available space */
    max-width: 20%; /* Maximum width of the flex item */
    min-width: 200px; /* Minimum width of the flex item */
}

.library-combined-svg {
    width: 100%;
    height: auto;
}

.library-combined-svg-container {
    border-radius: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensure the container takes up full height */
    padding: 0; /* Remove padding to allow precise positioning */
    margin: 0; /* Remove margin to allow precise positioning */
}

.library-close-button {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    right: 0;
    margin-right: 0.5rem; /* Add margin between buttons */
    background: none;
}

.library-download-button {
    position: absolute;
    top: 0;
    right: calc(1.5rem + 1.5rem); /* Adjust the distance from the right edge */
    width: 1.5rem;
    height: 1.5rem;
    background: none;
}

.library-close-button img,
.library-download-button img {
    width: 1.5rem; /* Adjust the width to make the images smaller */
    height: 1.5rem; /* Adjust the height to maintain aspect ratio */
}

.library-close-button:hover,
.library-download-button:hover {
    background: none; /* Remove background on hover */
}

.library-close-button:hover img,
.library-download-button:hover img {
    filter: brightness(150%) invert(20%) sepia(100%) saturate(10000%) hue-rotate(300deg); /* Adjust brightness and hue-rotate for bright red */
    transform: scale(1.1);
}


.library-monster-name {
    vertical-align:bottom;
    text-align: center;
    font-size: 2.5vh;
}

.library-combined-svg-container:hover {
  color: red;
    transform: scale(1.1);
}