@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Madimi+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.home-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: end;
    padding: 2rem;
    width: 80%; /* Adjusted width for responsiveness */
    margin: 0 auto;
}

.menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 10vw;
}

.header {
    margin-top: 25vh;
    text-align: center;
    vertical-align: top;
}

.header h1 {
    font-family: 'Lobster', cursive;
    font-size: 6vw; /* Adjusted font size for responsiveness */
    margin-bottom: 5px;
}

.header h2 {
    font-family: 'Varela Round', sans-serif;
    font-size: 6vw;
    margin-top: 0;
}

.header p {
    font-family: 'Madimi One', cursive;
    font-size: 2vw;
    margin-top: 5px;
}

.image-container {
    text-align: center;

}

.image-container img {
    max-width: 25vw;
    height: auto;
    margin-left:2rem;
}

.description p, ul, li{
    align-content: start;
    width:25vw;
    font-family: 'Varela Round', sans-serif;
    font-size: 1vw;
    margin-top: 0;
    line-height:1.2;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
    .home-container {
        flex-direction: column;
        align-items: center;
        padding: 1rem; /* Adjusted padding for smaller screens */
    }

    .header {
        margin-top: 2rem;
    }

    .header h1 {
        font-size: 8vw; /* Adjusted font size for smaller screens */
    }

    .header h2 {
        font-size: 8vw;
    }

    .header p {
        font-size: 3vw; /* Adjusted font size for smaller screens */
    }

}
