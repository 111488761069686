.menu-container {
    position: relative; /* Make this container relatively positioned */
}

.mainmenu {
    /*background-color: rgba(255, 255, 255, 0.9);*/
    position: fixed; /* Fixed position to keep it fixed on the screen */
    top: 0;
    left: 0;
    right: 0;
    height: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 10vw;
}

.welcome-message-container {
    position: fixed; /* Fixed position to keep it fixed on the screen */
    top: 15vh; /* Adjust top position as needed */
    right: 0; /* Adjust right position as needed */
    height: 5vh; /* Same height as the mainmenu container */
    display: flex;
    align-items: center;
    padding-right: 10vw; /* Match the padding of mainmenu */
    /*background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
}

.welcome-message {
    font-family: "Madimi One";
    font-size: 1.75rem;
    margin-right: 10px; /* Add some spacing */
    color: black;
}

.mainmenu button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mainmenu button.login-button {
    font-family: "Madimi One";
    font-size: 1.5rem;
    padding: 1rem 1rem;
    border-radius: 25px;
    width: 10vw;
    width: 10vw;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
}

.mainmenu button.logout-button {
    font-family: "Madimi One";
    font-size: 1.5rem;
    padding: 1rem 1rem;
    width: 10vw;
    border-radius: 25px;
    background-color: #db344d;
    color: #fff;
    text-decoration: none;
}

.mainmenu button.register-button {
    font-family: "Madimi One";
    font-size: 1.5rem;
    padding: 1rem 1rem;
    border-radius: 25px;
    background-color: #3498db;
    width: 10vw;
    color: #fff;
    text-decoration: none;
}

.mainmenu button:hover {
    background-color: #ffbdbd;
}

.home-button {
    order: 1;
    width: 5vw;
}

.home-img {
    width: 5vw;
}

.library-button {
    order: 2;
    width: 5vw;
    border-radius: 25px;

}

.library-img {
    margin-right: 5px;
    width: 5vw;
}

.makemonster-button{
    order: 3;
    width: 5vw;
    border-radius: 25px;
}

.makemonster-img{
    margin-right: 5px;
    width: 5vw;
}

.about-button{
    order: 4;
    width: 5vw;
    border-radius: 25px;
}

.about-img{
    margin-right: 5px;
    width: 5vw;
}

.blank-button {
    order: 5;
    width: 20vw;
    visibility: hidden;
}

.login-button {
    order: 6;
}

.logout-button {
    order: 6;
}

.register-button {
    order: 7;
}

.button-label {
    font-family: "Madimi One";
    font-size: 1.5rem;
    color: black;
}

.disabled {
    opacity: 0.5; /* Example styling */
    pointer-events: none; /* Example styling */
}



/* Adjusted font size for smaller screens */
@media only screen and (max-width: 768px) {
    .mainmenu button {
        font-size: 1rem;
    }

    /* Sidebar */
    .mobile-sidebar {
        position: fixed;
        top: 0;
        left: -300px; /* Initially hide the sidebar off-screen */
        width: 250px;
        height: 100vh;
        background: #fff;
        background: rgba(206,252,209,0.9);
        z-index: 998;
        transition: left 0.3s ease;
    }

    .mobile-sidebar.open {
        left: 0; /* Slide the sidebar into view */
    }

    /* Menu Toggle Icon */
    .mobile-menu-toggle {
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 999;
        cursor: pointer;
    }

    .mobile-menu-toggle .menu-icon {
        width: 30px;
        height: 30px;
        background: url('../images/menuIcon.svg') center/cover no-repeat;
    }

    .mobile-sidebar button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px;
        font-family: "Madimi One";
        font-size: 1.5rem;
        color: black;
    }

    .mobile-sidebar button img {
        width: 30px; /* Adjust image size as needed */
        margin-right: 10px; /* Add some spacing between image and text */
    }
}