.register-container {
    max-width: 80%; /* Adjust max-width to a percentage */
    width: 20rem;
    margin: 20rem auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.menu {
    position: absolute; /* Position the menu absolutely within the container */
    top: 0;
    left: 0;
    right: 0;
    height: 20vh; /* Adjusted height for larger icon and padding */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 10vw; /* Added padding at the top and sides */
}
input[type="text"],
input[type="password"] {
    width: calc(100% - 20px); /* Adjust width to a percentage */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}
input[type="passwordConfirmation"] {
    width: calc(100% - 20px); /* Adjust width to a percentage */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

submitButton {
    padding: 1rem 2rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 19rem; /* Adjust button width to a percentage */
    margin-bottom: 2rem;
    font-family: "Madimi One";
    font-size: medium;
}

.registration-error {
    color: red;
    font-family: "Madimi One";
    font-size: 20px;
}