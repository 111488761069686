.lobster-regular {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.madimi-one-regular {
    font-family: "Madimi One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.varela-round-regular {
    font-family: "Varela Round", sans-serif;
    font-weight: 400;
    font-style: normal;
}

/* Set container width to be narrower */
.makemonster-container {
    max-width: 80vw; /* Set the maximum width of the container */
    margin: 10vh auto;
    display: flex; /* Use flex display */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items along the main axis (vertically) */
}

/* Style the h1 header */
.makemonster-name {
    font-size: 4.5vw; /* Adjust font size to be 5% of the viewport width */
    margin-top: 25vh; /* Set margin-top to create space between header and SVG */
    display: flex; /* Use flex display */
    justify-content: center; /* Center text horizontally */
}
/* monsterMaker.css */
/* Add a container to hold the buttons */
.makemonster-buttons-container {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    margin-top: 17px; /* Add margin at the top */
}

/* Style the download, refresh, and add to library buttons */
.makemonster-refresh-btn,
.makemonster-download-btn,
.makemonster-add-to-library-btn {
    cursor: pointer;
    background: none;
    border: none;
    width: 13vw; /* Set a percentage width for better scaling */
    min-width: 100px; /* Set a minimum width to prevent buttons from getting too small */
    height: auto; /* Auto height to maintain aspect ratio */
    margin: 0 3vw; /* Add margin between buttons */
}

/* Style the plus button image */
.makemonster-plus-img, .makemonster-download-img {
    width: 100%; /* Set width to 100% */
    height: auto; /* Auto height to maintain aspect ratio */
}

.makemonster-button-label {
    margin-top: 0.5rem; /* Add margin between button image and label */
}

.monstermaker-combined-svg-container{
    height:30vh;
}

.monstermaker-combined-svg{
    width:20vw;
    height: 40vh;
}

.errorMessage{
    font-family: "Madimi One";
    color: red;
    font-size: 2rem;
}

.successMessage{
    font-family: "Madimi One";
    color: green;
    font-size: 2rem;
}

@media screen and (max-width: 768px) {
    .monstermaker-combined-svg {
        width: 60vw; /* Adjust SVG width for smaller screens */
        height: auto; /* Auto height to maintain aspect ratio */
    }
}
